import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroSection } from "../components/containers"
import { H1, H4, P } from "../components/typography"

const NotFoundPage = () => (
  <Layout noContact="true">
    <SEO title="404 — Not found" />
    <HeroSection>
      <H4>404 — Page Not Found</H4>
      <H1>We couldn’t find this page.</H1>
      <P>
        Maybe it’s out there, or maybe you’re early. Let’s find a better place
        for you to go. Visit our homepage.
      </P>
    </HeroSection>
  </Layout>
)

export default NotFoundPage
